<template>
	<div class="diy_list page_technician" id="technician_list">
		<div class="warp">
			<div class="container">
				<div class="row">
					<div class="col">
						<span class="title">技师列表</span>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<!-- 搜索栏 -->
						<div class="view">
							<b-form-input size="sm" class="mr-sm-2" placeholder="职称搜索" v-model="query['title']" />
							<b-form-input size="sm" class="mr-sm-2" placeholder="状态搜索" v-model="query['state']" />
							<b-form-input size="sm" class="mr-sm-2" placeholder="职工编号搜索" v-model="query['employee_number']" />
							<b-button size="sm" @click="search()" >
								<b-icon icon="search"/>
							</b-button>
						</div>
						<!-- /搜索栏 -->
					</div>
				</div>
				<div class="row">
					<div class="col">
						<!-- 筛选 -->
						<div class="view">
							<b-dropdown :text="title" variant="outline-dark" left>
							    <b-dropdown-item v-for="(o,i) in list_state" @click="filter_set(o,'state')" >
									{{ o }}
							    </b-dropdown-item>
							</b-dropdown>
						</div>
						<!-- /筛选 -->
					</div>
				</div>
				<div class="row">
					<div class="col">
						<!-- 排序 -->
						<div class="view">
							<b-dropdown text="排序" variant="outline-dark" left>
							    <b-dropdown-item v-for="(o, i) in list_sort" :key="i" @click="set_sort(o)" >
							        {{ o.name }}
							    </b-dropdown-item>
							</b-dropdown>
						</div>
						<!--/排序 -->
					</div>
				</div>
				<div class="row">
					<div class="col">
						<!-- 列表 -->
						<list_technician :list="list" />
						<!-- /列表 -->
					</div>
				</div>
				<div class="row">
					<div class="col overflow-auto flex_cc">
						<!-- 分页器 -->
						<diy_pager v-model="query['page']" :size="query['size']" :count="count" v-on:toPage="toPage" v-on:toSize="toSize" ></diy_pager>
						<!-- /分页器 -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import list_technician from "@/components/diy/list_technician.vue";
	import diy_pager from "@/components/diy/diy_pager";
	import mixin from "@/mixins/page.js";

	export default {
		mixins: [mixin],
		components: {
			diy_pager,
			list_technician
		},
		data() {
			return {
				url_get_list: "~/api/technician/get_list?like=0",

				// 查询条件
				query: {
					keyword: "",
					page: 1,
					size: 10,
					"title": "", // 职称
					"state": "", // 状态
					"employee_number": "", // 职工编号
				},

				// 排序内容
				list_sort: [{
						name: "创建时间从高到低",
						value: "`create_time` desc",
					},
					{
						name: "创建时间从低到高",
						value: "`create_time` asc",
					},
					{
						name: "更新时间从高到低",
						value: "`update_time` desc",
					},
					{
						name: "更新时间从低到高",
						value: "`update_time` asc",
					},
					{
						name: "职称正序",
						value: "`title` asc",
					},
					{
						name: "职称倒序",
						value: "`title` desc",
					},
					{
						name: "状态正序",
						value: "`state` asc",
					},
					{
						name: "状态倒序",
						value: "`state` desc",
					},
					{
						name: "职工编号正序",
						value: "`employee_number` asc",
					},
					{
						name: "职工编号倒序",
						value: "`employee_number` desc",
					},
				],
				// 状态列表
				"list_state": ['单点中','待轮牌','轮牌中','休假'],
			}
		},
		methods: {
			/**
			 * 筛选选择
			 */
			filter_set(o,key) {
			    if (o == "全部") {
			        this.query[key] = "";
			    } else {
			        this.query[key] = o;
			    }
			    this.search();
			},

			/**
			 * 排序
			 */
			set_sort(o) {
			    this.query.orderby = o.value;
			    this.search();
			},

			/**
			 * 筛选
			 */
			filter_state(o) {
				if (o == "全部") {
					this.query["state"] = "";
				} else {
					this.query["state"] = o;
				}
				this.search();
			},

			/**
			 * 重置
			 */
			reset() {
				this.query.title = ""
				this.query.state = ""
				this.query.employee_number = ""
				this.search();
			},

			// 返回条数
			toSize(i){
				this.query.size = i;
				this.first();
			},

			// 返回页数
			toPage(i){
				this.query.page = i;
				this.first();
			}
		},
		computed: {
		},
		created() {
		}
	}
</script>

<style>
</style>
